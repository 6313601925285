import React from 'react';
import { Container, Row, Col, Image, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'
import "./Styles/WigsTheatre.css"; // Ensure you create a corresponding CSS file for Wigs/Theatre
import Navigation from '../Nav/Navbar';
// Import your Wigs/Theatre images here
import theatreImage1 from '../pictures/WigsTheatre/theatre1.PNG';
import theatreImage2 from '../pictures/WigsTheatre/theatre2.PNG';
import theatreImage3 from '../pictures/WigsTheatre/theatre3.PNG';
import theatreImage4 from '../pictures/WigsTheatre/theatre4.PNG';
import theatreImage5 from '../pictures/WigsTheatre/theatre5.PNG';
import theatreImage6 from '../pictures/WigsTheatre/theatre6.PNG';
// import theatreImage7 from '../pictures/WigsTheatre/theatre7.PNG';
// import theatreImage8 from '../pictures/WigsTheatre/theatre8.PNG';
// import theatreImage9 from '../pictures/WigsTheatre/theatre9.PNG';

const WigsTheatrePage = () => {

    let navigate = useNavigate();

    const goToPreviousPage = () => {
      // Navigate to the previous page
      navigate('/editorial-fashion');
    };
  
    const goToNextPage = () => {
      // Navigate to the next page
      navigate('/bridaloccasion');
    };

  return (
    <>
      <Navigation />
      <Container className="wigs-theatre">
    <Row className="align-items-center mb-4 title-row">
          <Col md={3} className="text-center">
            <Button variant="link" onClick={goToPreviousPage} className="nav-button prev-button">
              Fashion <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </Col>
          <Col md={6} className="text-center">
            <h1>WIGS / THEATRE</h1>
          </Col>
          <Col md={3} className="text-center">
            <Button variant="link" onClick={goToNextPage} className="nav-button next-button">
              <FontAwesomeIcon icon={faArrowRight} /> Bridal/Event
            </Button>
          </Col>
        </Row>
        <Row className="align-items-center mb-4">
          <Col md={4}>
            <p>
              Explore the dramatic world of theatre with our expert wig and makeup services at Beauty By Holly Megan. 
              From historical pieces to modern dramas, ensure your characters look perfect on stage.
            </p>
          </Col>
          <Col md={8}>
            <h2>BOOK WITH US FOR...</h2>
            <Row className="gx-4">
              <Col sm={6} md={6} className="booking-option text-center">
                <h3>Stage Performances</h3>
                <p>Detailed character transformations for any performance.</p>
              </Col>
              <Col sm={6} md={6} className="booking-option text-center">
                <h3>Historical Accuracy</h3>
                <p>Authentic wigs and makeup that bring historical accuracy to life.</p>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Images and descriptions */}
        <Row>
          <Col md={4} className="text-center">
            <Image src={theatreImage1} fluid className="rounded" />
            <h3><em>Creative</em></h3>
          </Col>
          <Col md={4} className="text-center">
            <Image src={theatreImage2} fluid className="rounded"/>
            <h3><em>Contemporary</em></h3>
          </Col>
          <Col md={4} className="text-center">
            <Image src={theatreImage3} fluid className="rounded"/>
            <h3><em>Fashion</em></h3>
          </Col>
        </Row>
      </Container>

      {/* Gallery Section */}
      <Container className='gallery'>
        <Row className='gallery'>
          <Col xs={12} className='gallery'>
            <h2 className="text-center display-2">GALLERY</h2>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3 text-center">
            <Image src={theatreImage4} fluid className="rounded" />
          </Col>
          <Col md={4} className="mb-3 text-center">
            <Image src={theatreImage5} fluid className="rounded"/>
          </Col>
          <Col md={4} className="mb-3 text-center">
            <Image src={theatreImage6} fluid className="rounded"/>
          </Col>
        </Row>
        {/* <Row>
          <Col md={4} className="mb-3 text-center">
            <Image src={theatreImage7} fluid />
          </Col>
          <Col md={4} className="mb-3 text-center">
            <Image src={theatreImage8} fluid />
          </Col>
          <Col md={4} className="mb-3 text-center">
            <Image src={theatreImage9} fluid />
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default WigsTheatrePage;
