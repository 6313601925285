import React from 'react';
import { Container, Row, Col, Image, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import "./Styles/SpecialEffects.css"; // Your Special Effects CSS file
import Navigation from '../Nav/Navbar';
import effectImage1 from '../pictures/SpecialEffects/effect1.PNG';
import effectImage2 from '../pictures/SpecialEffects/effect2.PNG';
import effectImage3 from '../pictures/SpecialEffects/effect3.PNG';
import effectImage4 from '../pictures/SpecialEffects/effect4.PNG';
import effectImage5 from '../pictures/SpecialEffects/effect5.PNG';
import effectImage6 from '../pictures/SpecialEffects/effect6.PNG';
import effectImage7 from '../pictures/SpecialEffects/effect7.PNG';
import effectImage8 from '../pictures/SpecialEffects/effect8.PNG';
import effectImage9 from '../pictures/SpecialEffects/effect9.PNG';

const SpecialEffectsPage = () => {

  let navigate = useNavigate();

  const goToPreviousPage = () => {
    // Navigate to the previous page
    navigate('/bridaloccasion');
  };

  const goToNextPage = () => {
    // Navigate to the next page
    navigate('/halloween-bodyart');
  };


  return (
    <>
      <Navigation />
      <Container className="special-effects">
      <Row className="align-items-center mb-4 title-row">
          <Col md={3} className="text-center">
            <Button variant="link" onClick={goToPreviousPage} className="nav-button prev-button">
              Bridal / Events <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </Col>
          <Col md={6} className="text-center">
            <h1>SPECIAL EFFECTS</h1>
          </Col>
          <Col md={3} className="text-center">
            <Button variant="link" onClick={goToNextPage} className="nav-button next-button">
              <FontAwesomeIcon icon={faArrowRight} /> Halloween
            </Button>
          </Col>
        </Row>
        <Row className="align-items-center mb-4">
          <Col md={4}>
            <p>
              Dive into the world of Special Effects Makeup with Beauty By Holly Megan. 
              Transforming reality into creative fantasy, our SFX services cater to film, 
              theater, and themed events. Perfect for Halloween or any occasion requiring 
              that extra dramatic flair!
            </p>
          </Col>
          <Col md={8}>
            <h2>BOOK WITH US FOR...</h2>
            <Row className="gx-4">
              <Col sm={6} md={6} className="booking-option text-center">
                <h3>Horror</h3>
                <p>Spine-chilling horror SFX for films and Halloween.</p>
              </Col>
              <Col sm={6} md={6} className="booking-option text-center">
                <h3>Fantasy</h3>
                <p>Transformations into fantastical creatures and characters.</p>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Images and descriptions */}
        <Row>
          <Col md={4} className="text-center">
            <Image src={effectImage1} fluid className="rounded" />
            <h3><em>Creative</em></h3>
          </Col>
          <Col md={4} className="text-center">
            <Image src={effectImage2} fluid className="rounded" />
            <h3><em>Character Makeup</em></h3>
      </Col>
      <Col md={4} className="text-center">
        <Image src={effectImage6} fluid className="rounded"/>
        <h3><em>Horror</em></h3>
      </Col>
    </Row>
    </Container>

    {/* Additional rows for more images */}
    <Container className='gallery'>
    <Row className='gallery'>
          <Col xs={12} className='gallery'>
            <h2 className="text-center display-2">GALLERY</h2>
          </Col>
        </Row>
    <Row>
      <Col md={4} className="mb-3 text-center">
        <Image src={effectImage4} fluid className="rounded"/>
        
      </Col>
      <Col md={4} className="mb-3 text-center">
        <Image src={effectImage5} fluid className="rounded"/>
        
      </Col>
      <Col md={4} className="mb-3 text-center">
        <Image src={effectImage3} fluid className="rounded"/>
       
      </Col>
    </Row>
    <Row>
      <Col md={4} className="mb-3 text-center">
        <Image src={effectImage7} fluid className="rounded"/>
        
      </Col>
      <Col md={4} className="mb-3 text-center">
        <Image src={effectImage8} fluid className="rounded"/>
        
      </Col>
      <Col md={4} className="mb-3 text-center">
        <Image src={effectImage9} fluid className="rounded"/>
       
      </Col>
    </Row>
    </Container>
 
</>
);
};

export default SpecialEffectsPage;
