import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import "./Styles/BridalOccasion.css"
import Navigation from '../Nav/Navbar';
import bridal from '../pictures/Me front cover/bridal.PNG'
import bridal2 from '../pictures/Me front cover/bridal2.PNG'
import bridal3 from '../pictures/Me front cover/bridal3.PNG'
import gallery1 from '../pictures/Me front cover/gallery.PNG';
import gallery2 from '../pictures/Me front cover/gallery2.PNG';
import gallery3 from '../pictures/Me front cover/gallery3.PNG';
import gallery4 from '../pictures/Me front cover/gallery4.PNG';
import gallery5 from '../pictures/Me front cover/gallery5.PNG';
import gallery6 from '../pictures/Me front cover/gallery6.PNG';

const BridalPage = () => {

  let navigate = useNavigate();

  const goToPreviousPage = () => {
    // Navigate to the previous page
    navigate('/wigs-theatre');
  };

  const goToNextPage = () => {
    // Navigate to the next page
    navigate('/specialeffects');
  };


  return (
    <>
    <Navigation/>
    <Container className="bridal-occasion">
        <Row className="align-items-center mb-4 title-row">
          <Col md={3} className="text-center">
            <Button variant="link" onClick={goToPreviousPage} className="nav-button prev-button">
              Wigs/Theatre <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </Col>
          <Col  md={6} className="text-center">
            <h1>BRIDAL / OCCASION</h1>
          </Col>
          <Col md={3} className="text-center">
            <Button variant="link" onClick={goToNextPage} className="nav-button next-button">
              <FontAwesomeIcon icon={faArrowRight} /> Special effects
            </Button>
          </Col>
        </Row>
      <Row className="align-items-center mb-4">
        <Col md={4}>
         
          <p>
            Book in with Beauty By Holly Megan for any occasion! Our services are also mobile so no matter
            where or what occasion we can be there. We work with clients for birthdays, Eid, weddings, proms 
            and so much more. We believe that no matter the occasion you should start the day off feeling your best.
          </p>
        </Col>
        <Col md={8}>
  <h2>BOOK WITH US FOR...</h2>
  <Row className="gx-4">
    <Col sm={6} md={6} className="booking-option text-center">
      <h3>Weddings</h3>
      <p>Make everyone feel amazing on your wedding day with our diverse packages.</p>
    </Col>
   
    <Col  sm={6} md={6} className="booking-option text-center">
      <h3>Prom, Birthdays / Occasion</h3>
      <p>Book for any occasion, no matter how big.</p>
      {/* Add more text as needed */}
    </Col>
  </Row>
</Col>
      </Row>

      {/* Images and descriptions */}
      <Row>
        <Col md={4} className="text-center">
          <Image src={bridal3} fluid className="rounded" />
          <h3><em>Bridal</em></h3>
          {/* Description for bridal */}
        </Col>
        <Col md={4} className="text-center">
          <Image src={bridal2} fluid className="rounded" />
          <h3><em>Weddings</em></h3>
          {/* Description for weddings */}
        </Col>
        <Col md={4} className="text-center">
          <Image src={bridal} fluid className="rounded"/>
          <h3><em>Prom</em></h3>
          {/* Description for prom */}
        </Col>
      </Row>
      </Container>
        <Container className='gallery'>
      <Row className='gallery'>
          <Col xs={12} className='gallery'>
            <h2 className="text-center display-2">GALLERY</h2>
          </Col>
        </Row>
        <Row className="my-4 gallery">
          <Col md={4} className="mb-3 gallery">
            <Image src={gallery1} fluid className="rounded"/>
          </Col>
          <Col md={4} className="mb-3">
            <Image src={gallery2} fluid className="rounded"/>
          </Col>
          <Col md={4} className="mb-3">
            <Image src={gallery3} fluid className="rounded"/>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3">
            <Image src={gallery4} fluid className="rounded"/>
          </Col>
          <Col md={4} className="mb-3">
            <Image src={gallery5} fluid className="rounded"/>
          </Col>
          <Col md={4} className="mb-3">
            <Image src={gallery6} fluid className="rounded"/>
          </Col>
        </Row>
        </Container>
    
    </>
  );
};

export default BridalPage;
