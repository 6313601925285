import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import './Styles/Makeupstyles.css'; // Make sure to create the corresponding CSS file
import Makeupimage from "../pictures/Me front cover/makeup.PNG"
import Makeupimageright from "../pictures/Me front cover/makeupright.jpeg"
import Makeupimageright2 from "../pictures/Me front cover/makeupright2.jpeg"
import Navigation from '../Nav/Navbar';
import { useNavigate } from 'react-router-dom';


const ProjectsPage = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path); // Use the push method to navigate to the specified path
      };

  return (
    <>
    <Navigation/>
    <Container fluid className="projects-page">
      <Row className="h-100">
        <Col md={3} className="projects-menu">
        <Image src={Makeupimage} fluid />
        <div className="buttons-container">
        <Button variant="link" onClick={() => handleNavigation('/bridaloccasion')}>BRIDAL / EVENT</Button>
          <Button variant="link" onClick={() => handleNavigation('/specialeffects')}>SPECIAL EFFECTS</Button>
          <Button variant="link" onClick={() => handleNavigation('/halloween-bodyart')}>HALLOWEEN / BODYART</Button>
          <Button variant="link" onClick={() => handleNavigation('/editorial-fashion')}>EDITORIAL / FASHION</Button>
          <Button variant="link" onClick={() => handleNavigation('/wigs-theatre')}>WIGS / THEATRE</Button>
        </div>
          
        </Col>
        <Col md={9} className="projects-info">
  <h2>OUR MAKEUP STYLES</h2>
  <Row>
  <Col md={4} className="image-container text-center ">
    <Image src={Makeupimageright} fluid className="rounded" />
  </Col>
  <Col md={4} className="text-content">
    <p>At beauty by Holly Megan, we believe that makeup should be a reflection of your unique personality and style. I’m a skilled makeup artist that  is dedicated to creating looks that not only enhance your natural beauty but also align with your vision for the occasion. Whether it's a dreamy bridal look, a vibrant festival style, a spooky Halloween transformation, or a flawless photoshoot-ready finish, we tailor each session to your desires. We strive to ensure that you walk away with makeup you love and can confidently recommend. Let us bring your beauty dreams to life for weddings, festivals, photoshoots, fashion shows, and events/occasions.</p>
    
    <p>Don’t hesitate to contact us and enquire about any upcoming events.</p>
  </Col>
  <Col md={4} className="image-container text-center">
    <Image src={Makeupimageright2} fluid className="rounded"/> {/* Another image */}
  </Col>
  </Row>
</Col>
      </Row>
    </Container>
    </>
  );
};

export default ProjectsPage;
