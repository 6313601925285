import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import "./Styles/AboutSection.css"; // Ensure this CSS file exists for About Us page styling
import profileImage from '../pictures/Me front cover/profileImage.jpeg'; // Path to your profile image
import creativeMakeupImage from '../pictures/Me front cover/bridal2.PNG'; // Path to your creative makeup image
import bridalImage from '../pictures/Me front cover/bridal.PNG'; // Path to your bridal image
import Navigation from '../Nav/Navbar';

const AboutUsPage = () => {
  return (
    <>
    <Navigation />
    <Container className="editorial-fashion">
      <h1>ABOUT ME</h1>
      <Row className="align-items-center mb-4">
        <Col md={4}>
          <p>
          Meet Holly Megan Roberts, your personal artisan in the vibrant world of makeup. With a rich tapestry of experience spanning over five years, my journey is fueled by a passion that's been kindling since the tender age of thirteen. Serving Leeds, Halifax, and Bradford, my mobile makeup service ensures that no matter where you are, beauty is within reach.

I am proudly armed with a BA Honours in Fashion, Theatrical, Media Hair, and Makeup, affirming my expertise in creating looks that range from the demurely elegant to the daringly avant-garde. Each face tells its own story, and I am here to accentuate its narrative with every brush stroke.
          </p>
        </Col>
        <Col md={8}>
          <h2>MY PASSION</h2>
          <Row className="gx-4">
            <Col sm={6} md={6} className="booking-option text-center">
              <h3>Photo Shoots</h3>
              <p>Makeup that complements the theme of your photo session.</p>
            </Col>
            <Col sm={6} md={6} className="booking-option text-center">
              <h3>Runway</h3>
              <p>Bold and beautiful makeup looks that own the catwalk.</p>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Images and descriptions */}
      <Row>
        <Col md={4} className="text-center">
          <Image src={profileImage} fluid className="rounded" />
          <h3><em>High Fashion</em></h3>
        </Col>
        <Col md={4} className="text-center">
          <Image src={creativeMakeupImage} fluid  className="rounded"/>
          <h3><em>Commercial</em></h3>
        </Col>
        <Col md={4} className="text-center">
          <Image src={bridalImage} fluid className="rounded"/>
          <h3><em>Avant-Garde</em></h3>
        </Col>
      </Row>
    </Container>
      
    </>
  );
};

export default AboutUsPage;



