import React from 'react';
import { Container, Row, Col, Image, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'
import "./Styles/HalloweenBodyArt.css"; // Ensure you have a corresponding CSS file for Halloween/Body Art
import Navigation from '../Nav/Navbar';
// Import your Halloween/Body Art images here
import bodyArtImage1 from '../pictures/HalloweenBodyArt/bodyArt1.PNG';
import bodyArtImage2 from '../pictures/HalloweenBodyArt/bodyArt2.PNG';
import bodyArtImage3 from '../pictures/HalloweenBodyArt/bodyArt3.PNG';
import bodyArtImage4 from '../pictures/HalloweenBodyArt/bodyArt4.PNG';
import bodyArtImage5 from '../pictures/HalloweenBodyArt/bodyArt5.PNG';
import bodyArtImage6 from '../pictures/HalloweenBodyArt/bodyArt6.PNG';
import bodyArtImage7 from '../pictures/HalloweenBodyArt/bodyArt7.PNG';
import bodyArtImage8 from '../pictures/HalloweenBodyArt/bodyArt8.PNG';
import bodyArtImage9 from '../pictures/HalloweenBodyArt/bodyArt9.PNG';

const HalloweenBodyArtPage = () => {

    let navigate = useNavigate();

    const goToPreviousPage = () => {
      // Navigate to the previous page
      navigate('/specialeffects');
    };
  
    const goToNextPage = () => {
      // Navigate to the next page
      navigate('/editorial-fashion');
    };
  


  return (
    <>
      <Navigation />
      <Container className="halloween-body-art">
      <Row className="align-items-center mb-4 title-row">
          <Col md={3} className="text-center">
            <Button variant="link" onClick={goToPreviousPage} className="nav-button prev-button">
              Special effects <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </Col>
          <Col md={6} className="text-center">
            <h1>HALLOWEEN / BODY ART</h1>
          </Col>
          <Col md={3} className="text-center">
            <Button variant="link" onClick={goToNextPage} className="nav-button next-button">
              <FontAwesomeIcon icon={faArrowRight} /> Fashion
            </Button>
          </Col>
        </Row>
        <Row className="align-items-center mb-4">
          <Col md={4}>
            <p>
              Unleash your wild side with Beauty By Holly Megan’s Halloween and Body Art services. 
              From ghastly ghouls to fantastical fairies, our designs will make you stand out at any 
              costume party or event.
            </p>
          </Col>
          <Col md={8}>
            <h2>BOOK WITH US FOR...</h2>
            <Row className="gx-4">
              <Col sm={6} md={6} className="booking-option text-center">
                <h3>Scary</h3>
                <p>Full-body transformations into your scariest nightmares.</p>
              </Col>
              <Col sm={6} md={6} className="booking-option text-center">
                <h3>Artistic</h3>
                <p>Body painting that turns you into a living canvas.</p>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Images and descriptions */}
        <Row>
          <Col md={4} className="text-center">
            <Image src={bodyArtImage9} fluid className="rounded" />
            <h3><em>Zombie</em></h3>
          </Col>
          <Col md={4} className="text-center">
            <Image src={bodyArtImage7} fluid className="rounded"/>
            <h3><em>Ghostly</em></h3>
          </Col>
          <Col md={4} className="text-center">
            <Image src={bodyArtImage3} fluid className="rounded"/>
            <h3><em>Creative</em></h3>
          </Col>
        </Row>
      </Container>

      {/* Gallery Section */}
      <Container className='gallery'>
        <Row className='gallery'>
          <Col xs={12} className='gallery'>
            <h2 className="text-center display-2">GALLERY</h2>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3 text-center">
            <Image src={bodyArtImage4} fluid className="rounded"/>
          </Col>
          <Col md={4} className="mb-3 text-center">
            <Image src={bodyArtImage5} fluid className="rounded"/>
          </Col>
          <Col md={4} className="mb-3 text-center">
            <Image src={bodyArtImage6} fluid className="rounded"/>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3 text-center">
            <Image src={bodyArtImage2} fluid className="rounded"/>
          </Col>
          <Col md={4} className="mb-3 text-center">
            <Image src={bodyArtImage8} fluid className="rounded"/>
          </Col>
          <Col md={4} className="mb-3 text-center">
            <Image src={bodyArtImage1} fluid className="rounded"/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HalloweenBodyArtPage;
