import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import './Styles/Contacts.css';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '', // Added phone field
    appointmentType: '',
    message: '',
    date: '',
    numberOfPeople: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Format the date in English
    const formattedDate = new Date(formData.date).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });

    const endpoint = 'https://www.beautybyhollymegan.co.uk/send-email';
    const postData = {
      to: formData.email,
      name: formData.name,
      phone: formData.phone, // Include the new phone field
      appointmentType: formData.appointmentType,
      message: formData.message,
      date: formattedDate,
      numberOfPeople: formData.numberOfPeople
    };

    console.log('Sending:', postData);

    fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        alert('Thank you for your message. We will be in touch soon!');
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Error sending message. Please try again later.');
      });
  };

  return (
    <Container className="booking-container">
      <h1 className="text-center my-4">Book With Us!</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder="Enter your name" name="name" value={formData.name} onChange={handleChange} required />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" name="email" value={formData.email} onChange={handleChange} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Phone Number</Form.Label> {/* Added phone number field */}
          <Form.Control type="tel" placeholder="Enter phone number" name="phone" value={formData.phone} onChange={handleChange} pattern="[0-9]+" required />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Appointment Enquiry</Form.Label>
          <Form.Control as="select" name="appointmentType" value={formData.appointmentType} onChange={handleChange} required>
            <option value="">Select appointment enquiry</option>
            <option value="Weddings">Weddings</option>
            <option value="Occasions">Occasions</option>
            <option value="Full/ natural Glam">Full/ natural Glam</option>
            <option value="Photoshoots">Photoshoots</option>
            <option value="Editorial / RunWay">Editorial / RunWay</option>
            <option value="Halloween">Halloween</option>
            <option value="Special Effects">Special Effects</option>
            <option value="TV / Film">TV / Film</option>
            <option value="Events / Galas /Facepaint">Events / Galas /Facepaint</option>
            <option value="Body Art">Body Art</option>
            <option value="Theatre / Wigs">Theatre / Wigs</option>
            <option value="Training">Training</option>
            <option value="Other - please specify in the message">Other - please specify in the message</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Date</Form.Label>
          <Form.Control type="date" name="date" value={formData.date} onChange={handleChange} required />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Number of People</Form.Label>
          <Form.Control as="select" name="numberOfPeople" value={formData.numberOfPeople} onChange={handleChange} required>
            <option value="">Select number of people</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9+">9+</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Message</Form.Label>
          <Form.Control as="textarea" rows={6} placeholder="Your message" name="message" value={formData.message} onChange={handleChange} required />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
}

export default Contact;
