import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig'; // Make sure the path to your firebaseConfig is correct
import { useAuthState } from 'react-firebase-hooks/auth';

function Navigation() {
  const [user] = useAuthState(auth);

  const handleLogout = async () => {
    await signOut(auth);
  };

  return (
    <Navbar bg="black" variant="dark" expand="md" className="fixed-top">
      <Container fluid>
        {/* Brand on the left */}
        <Navbar.Brand as={Link} to="/">HollyMegan</Navbar.Brand>
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto">
            {/* Central navigation links */}
            <NavLink to="/">Home</NavLink>
            <NavLink to="/Makeupstyles">Makeup Styles</NavLink>
            <NavLink to="/training">Training</NavLink>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/gallery">Gallery</NavLink>
            <NavLink to="/reviews">Reviews</NavLink>
            <NavLink to="/contacts">Contact us?</NavLink>
          </Nav>
          <Nav>
            {/* Social Media Links on the right */}
            <Nav.Link href="https://www.facebook.com/beautybyhollymegan" target="_blank">
              <FontAwesomeIcon icon={faFacebook} />
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/misshollymegan/?igsh=YWxxZ3BnODAxbTFi&utm_source=qr" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </Nav.Link>
            <Nav.Link href="https://www.tiktok.com/@misshollymegan?_t=8lwL8jTiHAW&_r=1" target="_blank">
              <FontAwesomeIcon icon={faTiktok} />
            </Nav.Link>
            {user && (
              <NavLink to="#" onClick={handleLogout}>Logout</NavLink>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

// Custom NavLink component to handle routing
function NavLink({ to, children, onClick }) {
  return (
    <Nav.Link as={Link} to={to} className="nav-item" onClick={onClick}>
      {children}
    </Nav.Link>
  );
}

export default Navigation;

