import React from 'react';

import { Container } from 'react-bootstrap';
import backgroundImage from '../../src/pictures/Me front cover/Image.png';
import backgroundVideo from '../../src/pictures/Me front cover/backgroundhomepage.mov'; // Path to your video file
import Navigation from '../Nav/Navbar.jsx';

import './../Pages/Styles/Homepage.css'; // Ensure this CSS file exists

function Homepage() {
    return (
        <>
        <Navigation/>
        <div className="homepage">
            <video 
                className="background-video"
                autoPlay 
                loop 
                muted 
                playsInline 
                poster={backgroundImage}
                style={{ 
                    position: 'fixed', // or absolute, depending on other content
                    top: '50%', 
                    left: '50%', 
                    minWidth: '100%', 
                    minHeight: '100%', 
                    width: 'auto', 
                    height: 'auto', 
                    transform: 'translate(-50%, -50%)', 
                    zIndex: '-1' 
                }}
            >
                <source src={backgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            
            <Container className="text-center title-container">
                <h2 className='subtitle'>WELCOME TO</h2>
                <h1 className="title">Beauty By</h1>
                <h2 className="subtitle">HOLLY MEGAN</h2>
            </Container>
        </div>
        </>
    );
}

export default Homepage;