import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Styles/Training.css'; // This assumes you have a MyTraining.css file for your styles
import Profile from "../pictures/Me front cover/makeup.PNG"
import Training from "../pictures/Me front cover/makeupright.jpeg"
import Training2 from '../pictures/Me front cover/profileImage.jpeg';
const MyTraining = () => {
  return (
    <Container fluid className="my-training" style={{ minHeight: '100vh', backgroundColor: '#000' }}>
      <Row>
        {/* Left column for image and list of courses */}
        <Col xs={12} md={3} className="training-left">
          <img src={Profile} alt="Profile" className="profile-image" />
          <div className="course-list">
    <div className="course-item">
      <h3>LEVEL 3,4&5</h3>
      <hr />
    </div>
    <div className="course-item">
      <h3>LEVEL 6</h3>
      <hr />
    </div>
    <div className="course-item">
      <h3>PLOUISE ACADEMY</h3>
      <hr />
    </div>
    <div className="course-item">
      <h3>1-1 TUTORIALS</h3>
      <hr />
    </div>
    <div className="course-item">
      <h3>FUTURE PLANS: MORE TO COME</h3>
      <hr />
    </div>
  </div>
        </Col>

        {/* Right column for title and content */}
        <Col xs={12} md={9} className="training-right">
          <Row className="align-items-center">
            {/* Title row */}
            <Col xs={12} className="training-title text-center">
              <h2>MY TRAINING</h2>
            </Col>
          </Row>
          <Row  nogutters>
            {/* Content row with three columns */}
            <Col xs={12}  md={4} className="training-content">
              <img src={Training} alt="Training" className="content-image rounded" />
            </Col>
            <Col xs={12}  md={4} className="training-text">
              <div className="training-text-content">
       
        <p>What training do I have to run a successful training academy?</p>
        <p>I bring 8 years of experience in the industry and a deep understanding of the art form of social media and its influence on our field. I am on track to obtain my Level 3, 4 & 5 qualifications by the year's end and aim to secure a Level 6. My education includes studies at prestigious schools for creative makeup, and I've forged enduring connections in the industry.</p>
        <p>My commitment to learning doesn't stop there. I believe that no matter how skilled you believe you are, there's always room for growth. This ethos underpins my decision to refine my craft through advanced sessions at academies and to glean insights from how they operate. My training has included work with renowned figures in industry giants such as Plouise and Shadow and had 1-on-1 tutorials with experts active in film, solidifying my belief that the knowledge I offer comes from a place of extensive, hands-on experience.</p>
      </div>
    </Col>
    <Col xs={12}  md={4} className="training-content">
              <img src={Training2} alt="Training" className="content-image rounded" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default MyTraining;
