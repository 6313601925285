import React, { useEffect, useState } from 'react';
import { AdvancedImage } from "@cloudinary/react";
import { CloudinaryImage } from "@cloudinary/url-gen";
import { fill } from "@cloudinary/url-gen/actions/resize";
import './Styles/Gallery.css'; // Import CSS file for styling

const Gallery = () => {
    // Replace 'your-cloud-name' with your Cloudinary cloud name
    const cloudName = 'denhxdy6n';
    
    // Replace 'your-tag' with the tag you want to filter images by
    const tag = 'wigs';

    const [imagePublicIds, setImagePublicIds] = useState([]);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        // Fetch images with the specified tag from Cloudinary
        const fetchImagesByTag = async () => {
            try {
                const response = await fetch(`https://res.cloudinary.com/${cloudName}/image/list/${tag}.json`);
                if (response.ok) {
                    const data = await response.json();
                    const publicIds = data.resources.map(resource => resource.public_id);
                    setImagePublicIds(publicIds);
                } else {
                    console.error('Failed to fetch images:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching images:', error.message);
            }
        };

        fetchImagesByTag();
    }, [cloudName, tag]);

    const openLightbox = (publicId) => {
        setSelectedImage(publicId);
        setLightboxOpen(true);
    };

    // const closeLightbox = () => {
    //     setSelectedImage(null);
    //     setLightboxOpen(false);
    // };

    const toggleLightbox = () => {
        setLightboxOpen(!lightboxOpen);
    };

    return (
        <div className="gallery-container">
            <div className="thumbnails-container">
                {/* Render thumbnails for each image */}
                {imagePublicIds.map(publicId => (
                    <div key={publicId} onClick={() => openLightbox(publicId)}>
                        {/* Create a CloudinaryImage instance for each image */}
                        <AdvancedImage 
                            cldImg={new CloudinaryImage(publicId, { cloudName })
                                .resize(fill().width(300).height(300).aspectRatio('1:1'))} // Set a fixed width and height
                        />
                    </div>
                ))}
            </div>
            {lightboxOpen && (
    <div className="lightbox" onClick={toggleLightbox}>
        <div className="lightbox-content">
            <AdvancedImage 
                cldImg={new CloudinaryImage(selectedImage, { cloudName })
                    .resize(fill().width(500).aspectRatio('3:4'))} // Resize the image to 70% of the viewport width with a 16:9 aspect ratio
            />
        </div>
    </div>
)}
        </div>
    );
};

export default Gallery;




