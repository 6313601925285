import React from 'react';
import { Container, Row, Col, Image, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'
import "./Styles/EditorialFashion.css"; // Make sure to create a corresponding CSS file for Editorial/Fashion
import Navigation from '../Nav/Navbar';
// Import your Editorial/Fashion images here
import editorialImage1 from '../pictures/EditorialFashion/editorial1.PNG';
import editorialImage2 from '../pictures/EditorialFashion/editorial2.PNG';
import editorialImage3 from '../pictures/EditorialFashion/editorial3.PNG';
import editorialImage4 from '../pictures/EditorialFashion/editorial4.PNG';
import editorialImage5 from '../pictures/EditorialFashion/editorial5.PNG';
import editorialImage6 from '../pictures/EditorialFashion/editorial6.PNG';
import editorialImage7 from '../pictures/EditorialFashion/editorial7.PNG';
import editorialImage8 from '../pictures/EditorialFashion/editorial8.PNG';
import editorialImage9 from '../pictures/EditorialFashion/editorial9.PNG';

const EditorialFashionPage = () => {


  let navigate = useNavigate();

  const goToPreviousPage = () => {
    // Navigate to the previous page
    navigate('/halloween-bodyart');
  };

  const goToNextPage = () => {
    // Navigate to the next page
    navigate('/wigs-theatre');
  };



  return (
    <>
      <Navigation />
      <Container className="editorial-fashion">
      <Row className="align-items-center mb-4 title-row">
          <Col md={3} className="text-center">
            <Button variant="link" onClick={goToPreviousPage} className="nav-button prev-button">
              Halloween <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </Col>
          <Col md={6} className="text-center">
            <h1>EDITORIAL / FASHION</h1>
          </Col>
          <Col md={3} className="text-center">
            <Button variant="link" onClick={goToNextPage} className="nav-button next-button">
              <FontAwesomeIcon icon={faArrowRight} /> Wigs/Theatre
            </Button>
          </Col>
        </Row>
        <Row className="align-items-center mb-4">
          <Col md={4}>
            <p>
              Step into the spotlight with Beauty By Holly Megan’s Editorial and Fashion makeup services.
              Tailored for photo shoots, runway events, and fashion shows, we highlight your unique style with a touch of glamour.
            </p>
          </Col>
          <Col md={8}>
            <h2>BOOK WITH US FOR...</h2>
            <Row className="gx-4">
              <Col sm={6} md={6} className="booking-option text-center">
                <h3>Photo Shoots</h3>
                <p>Makeup that complements the theme of your photo session.</p>
              </Col>
              <Col sm={6} md={6} className="booking-option text-center">
                <h3>Runway</h3>
                <p>Bold and beautiful makeup looks that own the catwalk.</p>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Images and descriptions */}
        <Row>
          <Col md={4} className="text-center">
            <Image src={editorialImage1} fluid className="rounded" />
            <h3><em>High Fashion</em></h3>
          </Col>
          <Col md={4} className="text-center">
            <Image src={editorialImage2} fluid className="rounded"/>
            <h3><em>Commercial</em></h3>
          </Col>
          <Col md={4} className="text-center">
            <Image src={editorialImage3} fluid className="rounded"/>
            <h3><em>Avant-Garde</em></h3>
          </Col>
        </Row>
      </Container>

      {/* Gallery Section */}
      <Container className='gallery'>
        <Row className='gallery'>
          <Col xs={12} className='gallery'>
            <h2 className="text-center display-2">GALLERY</h2>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3 text-center">
            <Image src={editorialImage4} fluid className="rounded"/>
          </Col>
          <Col md={4} className="mb-3 text-center">
            <Image src={editorialImage5} fluid className="rounded"/>
          </Col>
          <Col md={4} className="mb-3 text-center">
            <Image src={editorialImage6} fluid className="rounded"/>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3 text-center">
            <Image src={editorialImage7} fluid className="rounded"/>
          </Col>
          <Col md={4} className="mb-3 text-center">
            <Image src={editorialImage8} fluid className="rounded"/>
          </Col>
          <Col md={4} className="mb-3 text-center">
            <Image src={editorialImage9} fluid className="rounded"/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditorialFashionPage;
