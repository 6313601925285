import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import '../src/Pages/Styles/pageturn.css';
import Homepage from './Pages/Homepage';
import AboutSection from './Pages/AboutSection';
import Makeupstyles from './Pages/Makeupstyles';
import Contacts from './Pages/Contacts';
import Gallery from './Pages/Gallery';
import Training from './Pages/Training';
import BridalOccasion from "./Pages/BridalOccasion";
import SpecialEffectsPage from './Pages/SpecialEffects';
import HalloweenBodyArt from './Pages/HalloweenBodyArt';
import EditorialFashionPage from './Pages/EditorialFashPage';
import WigsTheatrePage from './Pages/WigsTheatrePage';
import Navigation from './Nav/Navbar';
import Reviews from './Pages/Reviews';


import Register from './Pages/Register';

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="slide" timeout={300}>
        <div className="App">
          <Navigation />
          <Routes location={location}>
            <Route path="/" element={<Homepage />} />
            <Route path="/about" element={<AboutSection />} />
            <Route path="/makeupstyles" element={<Makeupstyles />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/training" element={<Training />} />
            <Route path="/bridaloccasion" element={<BridalOccasion />} />
            <Route path="/specialeffects" element={<SpecialEffectsPage />} />
            <Route path="/halloween-bodyart" element={<HalloweenBodyArt />} />
            <Route path="/editorial-fashion" element={<EditorialFashionPage />} />
            <Route path="/wigs-theatre" element={<WigsTheatrePage />} />
            <Route path="/reviews" element={<Reviews />} />
          
            <Route path="/register" element={<Register />} />
          </Routes>
         
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
        <AnimatedRoutes />
      </div>
    </Router>
  );
}

export default App;
