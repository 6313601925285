import React, { useState, useEffect } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore, auth } from '../firebaseConfig';
import { collection, addDoc, query, orderBy, serverTimestamp } from 'firebase/firestore';
import { useAuthState, useSignInWithEmailAndPassword, useCreateUserWithEmailAndPassword, useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
import { Modal, Button, Form, Container, Row, Col, Card, Alert, Image } from 'react-bootstrap';
import StarRatings from 'react-star-ratings';
import Filter from 'bad-words';
import '../Pages/Styles/Review.css';
import Reviewimage from '../pictures/Me front cover/review.PNG'; // Path to your creative makeup image

function Reviews() {
  const [user] = useAuthState(auth);
  const [signInWithEmailAndPassword, userCredential, loadingAuth, errorAuth] = useSignInWithEmailAndPassword(auth);
  const [createUserWithEmailAndPassword, userCreated, loadingSignUp, errorSignUp] = useCreateUserWithEmailAndPassword(auth);
  const [sendPasswordResetEmail, sending, errorReset] = useSendPasswordResetEmail(auth);
  const reviewsRef = collection(firestore, 'reviews');
  const reviewsQuery = query(reviewsRef, orderBy('createdAt', 'desc'));
  const [reviews, loading, error] = useCollectionData(reviewsQuery, { idField: 'id' });
  const [formValue, setFormValue] = useState('');
  const [rating, setRating] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [headline, setHeadline] = useState('');
  const [show, setShow] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [authError, setAuthError] = useState('');
  const [formError, setFormError] = useState('');
  const [resetEmail, setResetEmail] = useState('');

  const filter = new Filter();

  useEffect(() => {
    console.log('Reviews:', reviews);
    console.log('Loading:', loading);
    console.log('Error:', error);
  }, [reviews, loading, error]);

  useEffect(() => {
    if (userCredential || userCreated) {
      handleClose();
    }
  }, [userCredential, userCreated]);

  const handleClose = () => {
    setShow(false);
    setAuthError('');
    setFormError('');
  };

  const handleShow = () => setShow(true);

  const handleCloseReset = () => setShowReset(false);

  const handleShowReset = () => setShowReset(true);

  const submitReview = async (e) => {
    e.preventDefault();
    setFormError('');

    if (!name || !headline || !formValue || rating === 0) {
      setFormError('Please fill out all fields and provide a rating.');
      return;
    }

    if (filter.isProfane(name) || filter.isProfane(headline) || filter.isProfane(formValue)) {
      setFormError('Your review contains inappropriate language. Please remove it and try again.');
      return;
    }

    if (!user) {
      alert("You need to be logged in to submit a review.");
      return;
    }

    await addDoc(reviewsRef, {
      name,
      headline,
      text: formValue,
      rating: rating,
      createdAt: serverTimestamp(),
      user: user.uid
    });

    setFormValue('');
    setRating(0);
    setName('');
    setHeadline('');
    handleClose();
  };

  const handleAuth = async (e) => {
    e.preventDefault();
    setAuthError('');
    setFormError('');
    try {
      if (isLogin) {
        await signInWithEmailAndPassword(email, password);
        setAuthError('please check email and password!');
      } else {
        await createUserWithEmailAndPassword(email, password);
        setAuthError('Email Exisit please sign up with new email address');
      }
    } catch (error) {
      console.error("Authentication error:", error);
      setAuthError(error.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(resetEmail);
      setAuthError('Password reset email sent!');
      handleCloseReset();
    } catch (error) {
      console.error("Password reset error:", error);
      setAuthError(error.message);
    }
  };
  return (
    <>
      <Container className="review-container">
        <Row className="justify-content-center">
          <Col xs={12} md={9} className="review-content ">
            <Row className="my-5">
              <Col>
                <h2 className="text-center titlereview">Customer Reviews</h2>
                {loading && <p>Loading reviews...</p>}
                {error && <p>Error loading reviews: {error.message}</p>}
                <Row className="d-flex justify-content-center align-items-center mb-4">
                  <Col xs={12} md={4} className="text-center">
                    <Image src={Reviewimage} fluid className="rounded mx-auto d-block" />
                  </Col>
                  <Button className="custom-button" onClick={handleShow}>
                    Write a Review?
                  </Button>
                </Row>
                <Row className="mt-4">
                  {reviews && reviews.map(review => (
                    <Col key={review.id} xs={12} md={8} lg={6} className="mb-4">
                      <Card>
                        <Card.Body>
                        <Card.Title className="card-title-custom">
  {review.headline}
</Card.Title>

                          <StarRatings
                            rating={review.rating}
                            starRatedColor="Black"
                            numberOfStars={5}
                            name='rating'
                            starDimension="20px"
                            starSpacing="2px"
                          />
                          <Card.Text>{review.text}</Card.Text>
                          <Card.Subtitle className="mb-2 text-muted">by {review.name}</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{user ? "Write a Review" : isLogin ? "Login" : "Sign Up"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {authError && <Alert variant={authError.includes('successful') ? "success" : "danger"}>{authError}</Alert>}
            {formError && <Alert variant="danger">{formError}</Alert>}
            {!user && (
              <Form onSubmit={handleAuth}>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </Form.Group>
                <Button variant="primary" type="submit">
                  {isLogin ? "Login" : "Sign Up"}
                </Button>
                {isLogin && (
                  <Button variant="link" onClick={handleShowReset}>
                    Forgot Password?
                  </Button>
                )}
                <Button variant="link" onClick={() => setIsLogin(!isLogin)}>
                  {isLogin ? "Don't have an account? Sign Up" : "Already have an account? Login"}
                </Button>
              </Form>
            )}
            {user && (
              <Form onSubmit={submitReview}>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Your name" value={name} onChange={(e) => setName(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="formHeadline">
                  <Form.Label>Headline</Form.Label>
                  <Form.Control type="text" placeholder="Review headline" value={headline} onChange={(e) => setHeadline(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="formReview">
                  <Form.Label>Review</Form.Label>
                  <Form.Control as="textarea" rows={3} value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Write your review here" required />
                </Form.Group>
                <StarRatings
                  rating={rating}
                  starRatedColor="black"
                  changeRating={setRating}
                  numberOfStars={5}
                  name='rating'
                />
                <Button variant="primary" type="submit" className="mt-3">
                  Submit
                </Button>
              </Form>
            )}
          </Modal.Body>
        </Modal>
  
        <Modal show={showReset} onHide={handleCloseReset}>
          <Modal.Header closeButton>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleResetPassword}>
              <Form.Group controlId="formResetEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" value={resetEmail} onChange={(e) => setResetEmail(e.target.value)} required />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Send Reset Email
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
  
  
}

export default Reviews;